<template>
  <div>
    <b-modal
      id="modal-change-status-nota"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStatus') + articleNumber"
      hide-footer
      @close="onCancel"
    >
      <b-form style="display: flex; flex-direction:column">
        <p v-if="qFailed == false && showTeam == false && showControlCheck == false && showLocation == false">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(cStatus.newStateNota) }}</span> <br> {{ $t('ChangeStatusOfItem') }}
        </p>
        <p v-if="qFailed == false && showTeam == false && showControlCheck == false && showLocation == true">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(lStatus.newStateNota) }}</span> <br> {{ $t('ChangeStatusOfItem') }}
        </p>
        <p v-if="showTeam && !qFailed && tStatus.newState == 'InPatternCompleted'">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(tStatus.newStateNota) }}</span> <br> {{ $t('ChangeStatusOfItem') }}
        </p>
        <p v-if="showControlCheck == true && qFailed == false">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(controlCheckStatus.newStateNota) }}</span> <br>
          {{ $t('SelectPreferredTeam') }}
        </p>
        <vue-select
          v-if="showControlCheck == true && qFailed == false"
          v-model="team"
          :options="getDropdownTeamName"
          :placeholder="$t('SelectTeam')"
          label="teamName"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-checkbox
          v-if="completedSteps == true"
          v-model="checkCompleted"
        ><span style="color:red;">{{ $t('ChangeStatusConfirmation') }}</span></b-form-checkbox>
        <div v-if="checkCompleted">
          <b-form-group
            v-if="completedSteps == true"
            id="input-group-2"
            :label="$t('Team')"
          >
            <vue-select
              v-model="$v.completed.team.$model"
              :options="getDropdownTeamName"
              :placeholder="$t('SelectTeam')"
              :state="validateState1('team')"
              label="teamName"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <b-form-group
            v-if="completedSteps == true"
            id="input-group-2"
            :label="$t('State')"
          >
            <vue-select
              v-model="$v.completed.state.$model"
              :options="filteredOrderItemStates"
              label="name"
              :reduce="(e) => e.value"
              :placeholder="$t('Select')"
              :state="validateState1('state')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <b-form-group
            v-if="completedSteps == true"
            id="input-group-1"
            :label="$t('Description')"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="$v.completed.notes.$model"
              :state="validateState1('notes')"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('Required') }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group
          v-if="qFailed == true && qualitycheck < 2"
          id="input-group-2"
          :label="$t('Team')"
        >
          <vue-select
            v-model="form.team"
            :options="getDropdownTeamName"
            :placeholder="$t('SelectTeam')"
            label="teamName"
            aria-describedby="input-2-live-feedback"
          />
        </b-form-group>
        <b-form-group
          v-if="qFailed == true && qualitycheck < 2"
          id="input-group-2"
          :label="$t('State')"
        >
          <vue-select
            v-model="form.state"
            :options="filteredOrderItemStates"
            label="name"
            :reduce="(e) => e.value"
            :placeholder="$t('Select')"

            aria-describedby="input-2-live-feedback"
          />
        </b-form-group>
        <p v-if="qFailed == true">
          {{ $t('ProvideExplonation') }}
        </p>
        <b-form-group
          v-if="qFailed == true"
          id="input-group-1"
          :label="$t('Description')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('Required') }}</b-form-invalid-feedback>
        </b-form-group>
        <p v-if="showTeam && !qFailed && tStatus.newState != 'InPatternCompleted' ">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(tStatus.newStateNota) }}</span> <br>
          {{ $t('SelectPreferredTeam') }}
        </p>
        <vue-select
          v-show="tStatus.newState != 'InPatternCompleted'"
          v-if="showTeam && !qFailed && client != 'DrenushaXharra' || cStatus.newState == 'InPatternCompleted'"
          v-model="team"
          :options="getDropdownTeamName"
          :placeholder="$t('SelectTeam')"
          label="teamName"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-checkbox
          v-show="(id == team.teamNameId ) "
          v-if="(team !=null)"
          id="checkbox-1"
          v-model="isChecked"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
          @input="ShowThem(isChecked)"
        >
          {{ $t('ClickToSelect') }}
        </b-form-checkbox>
        <vue-select
          v-show="isChecked"
          v-if="controlCheckStatus.newState == 'InControlCheck' || cStatus.newState == 'InSewing' || (team !=null)"
          v-model="teamh"
          :options="getTeamOverview.users"
          :multiple="true"
          :placeholder="$t('SelectEmployee')"
          label="name"
          aria-describedby="input-1-live-feedback"
        />
        <p
          v-if=" showLocation == true && showTeam == false && client != 'DrenushaXharra' && lStatus.newState != 'InSewingQualityNotPassed' && lStatus.newState != 'InHandSewingQualityNotPassed' && lStatus.newState != 'InPatternQualityNotPassed'"
        >{{ $t('SelectPreferredLocation') }}</p>
        <vue-select
          v-if=" showLocation == true && showTeam == false && client != 'DrenushaXharra' && lStatus.newState != 'InSewingQualityNotPassed' && lStatus.newState != 'InHandSewingQualityNotPassed' && lStatus.newState != 'InPatternQualityNotPassed'"
          v-model="location"
          :options="getLocationsByType"
          :placeholder="$t('SelectLocation')"
          label="locationName"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-group
          v-if=" showLocation == true && showTeam == false && client != 'DrenushaXharra' "
          id="input-group-1"
          :label="`${$t('Notes')}:`"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="notes"
            aria-describedby="input-1-live-feedback"
          />
          <p
            v-if="yesButtonClicked"
            class="text-danger"
          >
            {{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}
          </p>
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="tStatus.newState === 'InPatternCompleted' || cStatus.newState === 'InSewingCompleted' || cStatus.newState === 'InHandSewingCompleted'"
        >
          <div style="display: flex;align-items: center;">

            <b-form-checkbox
              id="checkbox-1"
              v-model="isCheckedWarehouse"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            />
            <p style="margin-bottom: 0px;">
              Click if you want to sent it in a warehouse
            </p>
          </div>
          <b-form-group
            v-if="isCheckedWarehouse"
            id="input-group-1"
            :label="`${$t('Location')}:`"
            label-for="input-1"
          >
            <vue-select
              v-model="locationWarehouse"
              :options="getLocationsByType"
              :placeholder="$t('SelectLocation')"
              label="locationName"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Yes') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'
import { client } from '../../../domainConfig'


export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['completedSteps', 'cStatus', 'qFailed', 'articleNumber', 'orderFailed', 'showTeam', 'tStatus', 'showLocation', 'lStatus', 'showControlCheck', 'controlCheckStatus', 'id', 'qualitycheck'],
  data() {
    return {
      yesButtonClicked: false,
      isChecked: false,
      isCheckedWarehouse: false,
      form: {
        description: '',
        team: '',
        state: null,
      },
      completed: {
        notes: null,
        team: null,
        state: null,
      },
      checkCompleted: false,
      locationWarehouse: null,
      team: null,
      teamh: null,
      selectedTeamPerson: null,
      location: null,
      client: client.clientName,
      notes: null,

    }
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
    completed: {
      notes: {
        required,
      },
      team: {
        required,
      },
      state: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['getOrderItemStates', 'getTeamsNames', 'getDropdownTeamName', 'getLocationsByType', 'getTeamOverview']),
    filteredOrderItemStates() {
      return [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InPatternQualityPassed'),
          value: 'InPatternQualityPassed',
        },
        {
          name: this.$t('InPatternQualityNotPassed'),
          value: 'InPatternQualityNotPassed',
        },
        {
          name: this.$t('InPatternCompleted'),
          value: 'InPatternCompleted',
        },
        {
          name: this.$t('InSewingAssigned'),
          value: 'InSewingAssigned',
        },
        {
          name: this.$t('InSewingConfirmed'),
          value: 'InSewingConfirmed',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InSewingQualityPassed'),
          value: 'InSewingQualityPassed',
        },
        {
          name: this.$t('InSewingQualityNotPassed'),
          value: 'InSewingQualityNotPassed',
        },
        {
          name: this.$t('InSewingCompleted'),
          value: 'InSewingCompleted',
        },
        {
          name: this.$t('InstallationAssigned'),
          value: 'InHandSewingAssigned',
        },
        {
          name: this.$t('InstallationConfirmed'),
          value: 'InHandSewingConfirmed',
        },
        {
          name: this.$t('Installation'),
          value: 'InHandSewing',
        },
        {
          name: this.$t('InstallationQualityPassed'),
          value: 'InHandSewingQualityPassed',
        },
        {
          name: this.$t('InstallationQualityNotPassed'),
          value: 'InHandSewingQualityNotPassed',
        },
        {
          name: this.$t('InstallationCompleted'),
          value: 'InHandSewingCompleted',
        },
        {
          name: this.$t('InControlCheckAssigned'),
          value: 'InControlCheckAssigned',
        },
        {
          name: this.$t('InControlCheckConfirmed'),
          value: 'InControlCheckConfirmed',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },
        {
          name: this.$t('InPackingAssigned'),
          value: 'InPackingAssigned',
        },
        {
          name: this.$t('InPackingConfirmed'),
          value: 'InPackingConfirmed',
        },
        {
          name: this.$t('InPacking'),
          value: 'InPacking',
        },
        {
          name: this.$t('ReadyToDeliver'),
          value: 'ReadyToDeliver',
        },
        {
          name: this.$t('Delivered'),
          value: 'Delivered',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
      ];
    },
  },
  watch: {
    checkCompleted(value) {
      if (value == false) {
        this.completed.notes = null
        this.completed.team = null
        this.completed.state = null
      }
    },
    team(value) {
      this.teamh = null
      if (value != null) {
        this.teamByTeamNameId(value.teamNameId)
      }
    },
    isCheckedWarehouse(value) {
      if (value == false) {
        this.locationWarehouse = null
      }
    },
    isChecked(value) {
      if (value == false) {
        this.team.processingUserIds = null
        this.teamh = null
      }
    },
  },
  methods: {
    ...mapActions([
      'teamByTeamNameId',
    ]),

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateState1(name) {
      const { $dirty, $error } = this.$v.completed[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if (this.qFailed == true) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        if (this.form.team == null) {
          this.orderFailed.teamNameId = null
        } else {
          this.orderFailed.teamNameId = this.form.team.teamNameId
        }
        if (this.form.state == null) {
          this.orderFailed.newState = null
        } else {
          this.orderFailed.newState = this.form.state
        }
        if (this.form.description == null) {
          this.orderFailed.description = null
        } else {
          this.orderFailed.description = this.form.description
        }
        this.$emit('qualityChFailed', this.orderFailed)
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.form.description = ''
        this.form.team = null
        this.form.state = null
        this.lStatus.notes = null
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.completedSteps == true) {
        let forceChangeOrderItem
        if (this.completed.team == null) {
          this.cStatus.newTeamNameId = null
          forceChangeOrderItem = false
        } else {
          this.cStatus.newTeamNameId = this.completed.team.teamNameId
          forceChangeOrderItem = true
        }
        if (this.completed.state != null) {
          this.cStatus.newState = this.completed.state
        }
        if (this.completed.notes == null) {
          this.cStatus.notes = null
        } else {
          this.cStatus.notes = this.completed.notes
        }
        if (this.checkCompleted == true) {
          this.$v.completed.$touch();
          if (this.$v.completed.$anyError) {
            return;
          }
        }
        const objekti1 = { cStatus: this.cStatus, forceChangeOrderItems: forceChangeOrderItem }
        const objekti2 = this.cStatus
        delete this.cStatus.newStateNota
        this.$emit('changeOrderStatus', forceChangeOrderItem == true ? objekti1 : objekti2)
        this.cStatus.newState = null;
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.lStatus = null
        this.tStatus.processingUserIds = null
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        this.isCheckedWarehouse = false
        this.controlCheckStatus.processingUserIds = null;
        this.lStatus.notes = null
        this.checkCompleted = false
      } else if (this.showTeam == true && this.showLocation == false) {
        this.isChecked = false;
        if (this.client != 'DrenushaXharra' || (this.client != 'FjollaNila' && this.tStatus.newState != 'InHandSewing')) {
          if (this.tStatus.newState != 'InPatternCompleted') {
            this.tStatus.newTeamNameId = this.team.teamNameId
          }
          if (this.teamh != null) {
            this.tStatus.processingUserIds = this.teamh.map((user) => { return user.userId });
          }
        }
        if (this.locationWarehouse != null) {
          this.tStatus.locationId = this.locationWarehouse.locationId
        }
        delete this.tStatus.newStateNota
        this.$emit('teamName', this.tStatus)
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.tStatus.processingUserIds = null
        this.cStatus.processingUserIds = null;
        this.isCheckedWarehouse = false
        this.controlCheckStatus.processingUserIds = null;
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        this.lStatus.notes = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.showLocation == true && this.showTeam == false) {
        this.lStatus.locationId = this.location ? this.location.locationId : null;
        if (this.client != 'DrenushaXharra') {
          if (!this.notes || this.notes.trim().length < 3) {
            this.yesButtonClicked = true

            // If notes is empty or less than 3 characters, show an error message
            this.$v.form.$touch();
            return;
          }
        }
        this.lStatus.notes = this.notes ? this.notes : null;
        delete this.lStatus.newStateNota
        this.$emit('locationName', this.lStatus)
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.team = ''
        this.tStatus.processingUserIds = null
        this.cStatus.processingUserIds = null;
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.controlCheckStatus.processingUserIds = null;
        this.lStatus.notes = null
        this.notes = null
        this.yesButtonClicked = false
        this.isCheckedWarehouse = false
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.tStatus.newState == 'InPatternCompleted' || this.cStatus.newState == 'InSewing') {
        console.log(this.cStatus)
        if (this.client === 'FjollaNila') {
          this.cStatus.newTeamNameId = this.id;
        }
        if (this.teamh != null) {
          this.cStatus.processingUserIds = this.teamh.map((user) => { return user.userId });
          this.isChecked = false;
        }
        if (this.locationWarehouse != null) {
          this.cStatus.locationId = this.locationWarehouse.locationId
        }
        delete this.lStatus.cStatus
        this.$emit('changeOrderStatus', this.cStatus)
        this.cStatus.newState = null;
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.isCheckedWarehouse = false
        this.cStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.tStatus.locationId = null
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        this.tStatus.processingUserIds = null
        this.controlCheckStatus.processingUserIds = null;
        this.lStatus.notes = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.showControlCheck == true) {
        this.controlCheckStatus.newTeamNameId = this.team.teamNameId
        if (this.teamh != null) {
          this.controlCheckStatus.processingUserIds = this.teamh.map((user) => { return user.userId });
          this.isChecked = false;
        }
        delete this.controlCheckStatus.newStateNota
        this.$emit('controlCheck', this.controlCheckStatus)
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.team = null
        this.isCheckedWarehouse = false
        this.isChecked = false;
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.tStatus.processingUserIds = null
        this.cStatus.processingUserIds = null;
        this.controlCheckStatus.processingUserIds = null;
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        this.lStatus.notes = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else {
        if (this.client === 'FjollaNila' && this.cStatus.newState == 'InControlCheck') {
          this.cStatus.newTeamNameId = this.id;
        }
        if (this.locationWarehouse != null) {
          this.cStatus.locationId = this.locationWarehouse.locationId
        }
        delete this.cStatus.newStateNota
        this.$emit('changeOrderStatus', this.cStatus)
        this.cStatus.newState = null;
        this.checkCompleted = false
        this.$refs.modal.hide()
        this.lStatus = null
        this.tStatus.processingUserIds = null
        this.cStatus.locationId = null
        this.tStatus.locationId = null
        this.tStatus.newState = null
        this.cStatus.newState = null
        this.cStatus.newTeamNameId = null
        this.tStatus.newTeamNameId = null
        this.controlCheckStatus.newTeamNameId = null
        this.isCheckedWarehouse = false
        this.controlCheckStatus.processingUserIds = null;
        this.lStatus.notes = null
      }
      this.$nextTick(() => {
        // this.$emit('qualityChFailed', this.orderFailed);
        // this.$emit('teamName', this.tStatus);
        // this.$emit('teamName', this.lStatus);
        // this.$emit('changeOrderStatuss', this.cStatus);
        this.checkCompleted = false
        this.$refs.modal.hide();
        this.form.description = '';
        this.form.team = null
        this.form.state = null
        this.locationWarehouse = null
        this.team = null;
        this.isCheckedWarehouse = false
        this.location = null;
        this.$v.$reset();
      });
    },
    onCancel() {
      this.$refs.modal.hide()
      this.form.description = ''
      this.form.team = null
      this.form.state = null
      this.tStatus.newState = null
      this.cStatus.newState = null
      this.locationWarehouse = null
      this.isCheckedWarehouse = false
      this.yesButtonClicked = false
      this.notes = null
      this.checkCompleted = false
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .buttonat{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
</style>
